import React from 'react';
import styles from './horizontal_timeline.module.scss';

interface HorizontalTimelineProps {
}

const HorizontalTimeline: React.FC<HorizontalTimelineProps> = () => {
    return <div className={styles.horizontal_timeline}>
        <article>
            <dl>
                {[
                    {name: '核心园区', value: '2020.1',},
                    {name: '高新分园区', value: '2022.7',},
                    {name: '路南园区', value: '2022.8',},
                    {name: '开平（博玉）园区', value: '2022.9',},
                    {name: '迁安园区', value: '2022.9',},
                    {name: '曹妃甸园区', value: '2022.12',},
                    {name: '开平（蔚徕）园区', value: '2023.4',},
                    {name: '路北园区', value: '2023.11',},
                    {name: '玉田园区', value: '2023.12',},
                    {name: '丰南园区', value: '2024.1',},
                ].map((it, index) => {
                    return <div key={index} className="cell">
                        <div className="cell-content">
                            <dt>{it.value}</dt>
                            <dd>{it.name}</dd>
                        </div>
                    </div>
                })}
            </dl>
        </article>
    </div>;
}

export default HorizontalTimeline;
